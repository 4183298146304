import React from "react";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';
// pages for this product
import PrivacyPolicyPage from "../components/Documents/PrivacyPolicyPage.jsx";

export default () => (
  <PrivacyPolicyPage />
);
